import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import StickyCards from './StickyCards';

const HeroMobile = ({bought=false,}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="hero-cont">
      {windowWidth <=500 &&  <StickyCards />}
      </div>
  )
}

export default HeroMobile