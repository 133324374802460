import React, { useEffect, useState } from "react";
import "./styles/BrowseCourses.css";
import bcdes from "../Assests/bcdes.svg";
import code from "../Assests/code.svg";
import business from "../Assests/business.svg";
import Coursecard from "./Coursecard";
import ae from "../../../Assests/Images/aftereffects.jpg";
import ai from "../../../Assests/Images/illustrator.webp";
import appdev from "../../../Assests/Images/app.svg";
import cp from "../../../Assests/Images/dynamic.svg";
import cpp from "../../../Assests/Images/c++.png";
import DBMS from "../../../Assests/Images/dbms.jpg";
import Devops from "../../../Assests/Images/devops.png";
import dm from "../../../Assests/Images/digital.jpg";
import ds from "../../../Assests/Images/science.webp";
import dsa from "../../../Assests/Images/datastructure.png";
import fm from "../../../Assests/Images/fmv.png";
import uxresearch from "../../../Assests/uxresearch.png";
import frm from "../../../Assests/Images/risk.webp";
import Hacking from "../../../Assests/Images/hacking.webp";
import java from "../../../Assests/Images/java.png";
import mern from "../../../Assests/Images/mern.webp";
import php from "../../../Assests/Images/web.webp";
import pr from "../../../Assests/Images/premiere.webp";
import ps from "../../../Assests/Images/photoshop.jpg";
import python from "../../../Assests/Images/python.png";
import react from "../../../Assests/Images/reactnative.svg";
import stockmarket from "../../../Assests/Images/fundamental.png";
import ta from "../../../Assests/Images/technical.png";
import uiux from "../../../Assests/Images/uiux.jpg";
import dsawp from "../../../Assests/Images/dsawp.png";
import dataanalytics from "../../../Assests/Images/dataanalytics.png";
import ml from "../../../Assests/Images/ml.webp";

const BrowseCourses = () => {
  const [courses, setCourses] = useState([]);
  const [active, setActive] = useState(1);
  const course = [
    {
      text: "Web Development with PHP/MySQL ",
      cimg: php,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "webdevelopment",
      type: "development",
    },
    {
      text: "Web Development with MERN Stack",
      cimg: mern,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "mernstack",
      type: "development",
    },
    {
      text: "React JS",
      cimg: react,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "reactjs",
      type: "development",
    },
    {
      text: "React Native",
      cimg: react,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "reactnative",
      type: "development",
    },
    {
      text: "App Development",
      cimg: appdev,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "appdevelopment",
      type: "development",
    },
    {
      text: "C++",
      cimg: cpp,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "cpp",
      type: "it",
    },

    {
      text: "Java",
      cimg: java,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "java",
      type: "it",
    },

    {
      text: "Python",
      cimg: python,
      col: "#0086D1",
      bg: "rgba(0, 134, 209, 0.11)",
      slug: "python",
      type: "it",
    },
    {
      text: "Data Structures & Algorithms",
      cimg: dsa,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "datastructure",
      type: "it",
    },
    {
      text: "Competitive Programming",
      cimg: cp,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "competitiveprogramming",
      type: "it",
    },
    {
      text: "Ethical Hacking",
      cimg: Hacking,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "ethicalhacking",
      type: "it",
    },

    {
      text: "Data Science",
      cimg: ds,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "datascience",
      type: "it",
    },
    {
      text: "Data Analytics",
      cimg: dataanalytics,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "dataanalytics",
      type: "bussiness",
    },
    {
      text: "Power BI",
      cimg: "/clogos/powerbi.webp",
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "powerbi",
      type: "bussiness",
    },
    {
      text: "Machine Learning",
      cimg: ml,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "machinelearning",
      type: "it",
    },
    {
      text: "DSA With Python",
      cimg: dsawp,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "dsawithpython",
      type: "it",
    },

    {
      text: "DBMS",
      cimg: DBMS,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "dbms",
      type: "it",
    },
    {
      text: "DevOPS",
      cimg: Devops,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "devops",
      type: "it",
    },

    {
      text: "Digital Marketing",
      cimg: dm,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "digitalmarketing",
      type: "bussiness",
    },

    {
      text: "Stock Market",
      cimg: stockmarket,
      col: "#2BA700",
      bg: "rgba(43, 167, 0, 0.13)",
      slug: "fundamentalanalysis",
      type: "bussiness",
    },
    {
      text: "Technical Analysis",
      cimg: ta,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "technicalanalysis",
      type: "bussiness",
    },

    {
      text: "Financial Modelling & Valuation",
      cimg: fm,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "financialmodelling",
      type: "bussiness",
    },
    {
      text: "Financial Risk Managment",
      cimg: frm,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "riskmanagement",
      type: "bussiness",
    },

    {
      text: "Adobe Photoshop",
      cimg: ps,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "photoshop",
      type: "drawing",
    },

    {
      text: "Adobe Premiere Pro",
      cimg: pr,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "premierepro",
      type: "drawing",
    },
    {
      text: "Adobe After Effects",
      cimg: ae,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "aftereffects",
      type: "drawing",
    },
    {
      text: "Adobe Illustrator",
      cimg: ai,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "illustrator",
      type: "drawing",
    },
    {
      text: "UI UX Designing",
      cimg: uiux,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "uiux",
      type: "drawing",
    },
    {
      text: "UX Research",
      cimg: uxresearch,
      col: "#D17100",
      bg: "rgba(209, 113, 0, 0.11)",
      slug: "uxresearch",
      type: "drawing",
    },
  ];

  useEffect(() => {
    setCourses(course);
  }, []);

  return (
    <div className="bc-cont" id="courses">
      <div className="home-wu-top">
        <div className="home-hr-head headings-col">
          Browse our <b className="home-hr-head headings-col-col">Courses</b>
        </div>
        <div className="home-wu-text">
          Explore a wide range of courses where learning is fun, easy, and
          absolutely free!
        </div>
      </div>
      <div className="bc-mid-parent">
        <div className="bc-mid">
          <div
            className={`bc-mid-chip pointer ${
              active === 1 ? "chip-active" : ""
            }`}
            onClick={() => {
              setActive(1);
              setCourses(course);
            }}
          >
            All Courses
          </div>
          <div
            className={`bc-mid-chip pointer ${
              active === 2 ? "chip-active" : ""
            }`}
            onClick={() => {
              setActive(2);
              setCourses((cs) => course.filter((c) => c.type === "drawing"));
            }}
          >
            <img src={bcdes} alt="bcdes" />
            Design
          </div>
          <div
            className={`bc-mid-chip pointer ${
              active === 3 ? "chip-active" : ""
            }`}
            onClick={() => {
              setActive(3);
              setCourses((cs) =>
                course.filter((c) => c.type === "development")
              );
            }}
            // style={{
            //   border: "1.5px solid rgba(0, 134, 209, 0.65)",
            //   color: "rgba(0, 134, 209, 0.70)",
            // }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M13.125 19.25H9.625"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.375 23.625V19.25"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.9375 19.25V23.625"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 19.25V23.625"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.9375 21.4375H7"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23.1875 19.25V23.625H25.8125"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.75 23.625V19.25L17.9375 22.3125L20.125 19.25V23.625"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.25 14.875V4.375C5.25 4.14294 5.34219 3.92038 5.50628 3.75628C5.67038 3.59219 5.89294 3.5 6.125 3.5H16.625L22.75 9.625V14.875"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.625 3.5V9.625H22.75"
                stroke="#844FDB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Development
          </div>
          <div
            className={`bc-mid-chip pointer ${
              active === 4 ? "chip-active" : ""
            }`}
            onClick={() => {
              setActive(4);
              setCourses((cs) => course.filter((c) => c.type === "it"));
            }}
            // style={{
            //   border: "1.5px solid rgba(43, 167, 0, 0.53)",
            //   color: "rgba(43, 167, 0, 0.70)",
            // }}
          >
            <img src={code} alt="bcdes" /> IT & Software
          </div>
          <div
            className={`bc-mid-chip pointer ${
              active === 5 ? "chip-active" : ""
            }`}
            onClick={() => {
              setActive(5);
              setCourses((cs) => course.filter((c) => c.type === "bussiness"));
            }}
          >
            <img src={business} alt="bcdes" /> Business
          </div>
        </div>
        <div className="bc-chips-cont">
          {courses.map((course, index) => {
            return (
              <Coursecard
                key={index}
                cimg={course.cimg}
                text={course.text}
                col={course.col}
                bg={course.bg}
                slug={course.slug}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BrowseCourses;
