import "./PaymentOld.css";
import useCart from "../contexts/Cart";
import contents from "../contents/Contents.json";

import { useEffect, useState } from "react";
import logo from "../Assests/logo.svg";

import "./Payment.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Payment = () => {
  const { selectedCategory, setSelectedCategory } = useCart();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedCategory.length !== 0) {
      Cookies.set("selected", selectedCategory);
    }
    if (Cookies.get("selected")) {
      setSelectedCategory(Cookies.get("selected"));
    }

    const name = Cookies.get("user_name");
    const email = Cookies.get("user_email");
    if (
      !name ||
      !email ||
      (selectedCategory.length === 0 && !Cookies.get("selected"))
    ) {
      navigate("/");
    } else {
      setName(name);
      setEmail(email);

      fetch(
        `https://api.tutedude.com/lms/api/user/saveLeads?email=${email}&course=${selectedCategory}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      window.fbq("track", "AddToCart");
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", "AW-711435738");

      gtag("event", "conversion", {
        send_to: "AW-711435738/LjBwCMWls7oDENrLntMC",
      });
    }
  }, []);

  const [completed, setCompleted] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [applied, setApplied] = useState(false);

  var coupons = {
    OFF200: 200,
    DevTest: 698,
    ANUDEEPA50: 300,
    NAVNEETA50: 300,
    MANTRA30: 200,
    GENERIC30: 300,
    HTA30: 300,
    EXCEL200: 200,
    GKARMY: 100,
    SKILL100: 100,
    PREP200: 200,
    INFOHOOP: 200,
    HACK200: 200,
    TD100: 100,
    DUDE30: 200,
    Dude30: 200,
    dude30: 200,
    DUDE300: 300,
    JEET200: 200,
    SRMKZILLA: 300,
    DIWALI300: 300,
    DSSECRETFREESHIV: 698,
  };
  const feesback = ["3MONTHSREFUND", "3MONTHREFUND", "FEESBACK", "LEARNIT"];

  const handleInputChange = (e) => {
    setApplied(false);
    setDiscount(0);
    setCoupon(e.target.value);
    // console.log(e.target.value);
  };
  const handleApply = () => {
    if (
      feesback.findIndex(
        (fees) => fees.toLocaleLowerCase() === coupon.toLocaleLowerCase()
      ) !== -1
    ) {
      alert(
        "Congrats! You are eligible to get 100% feesback on completing this course within 3 months :)"
      );
      setDiscount(0);
      setApplied(true);
    } else if (coupons[coupon]) {
      setDiscount(coupons[coupon]);
      setApplied(true);
    } else if (!coupons[coupon]) {
      try {
        fetch("https://api.tutedude.com/verify?coupon=" + coupon, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setDiscount(200);
              setApplied(true);
            } else {
              alert("Please Enter a Valid Coupon");
              setDiscount(0);
            }
          });
      } catch (err) {
        alert("Please Enter a Valid Coupon");
        setDiscount(0);
      }
    }
  };

  const checkoutHandler = async (amount, name, email, course, coupon) => {
    const {
      data: { key },
    } = await axios.get("https://api.tutedude.com/lms/API/getkey");

    const {
      data: { order },
    } = await axios.post("https://api.tutedude.com/lms/API/checkout", {
      amount,
      name,
      email,
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "TuteDude",
      description: `TuteDude ${contents[selectedCategory]?.name.replace(
        /\//g,
        ""
      )} Course Purchase`,
      image: logo,
      order_id: order.id,
      receipt: email,
      prefill: {
        name,
        email,
        contact: Cookies.get("user_phone") || "",
      },
      handler: function (response) {
        navigate(
          `/letskillit?amt=${amount}&pid=${response.razorpay_payment_id}`
        );
      },
      notes: {
        customer_name: name,
        customer_email: email,
        course: course,
        coupon: coupon,
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };
  return (
    <>
      <Helmet>
        <title>Razorpay Payment</title>
      </Helmet>
      <div className="body">
        <header className="header">
          <img src="https://tutedude.in/feesbackassets/logo.png" alt="" />
        </header>
        <main className="main">
          <h1 className="h1">Purchase Summary</h1>
          <div className="linee"></div>
          <div className="grid">
            <div>
              <div className="course">
                <div>
                  <h3>{contents[selectedCategory]?.name}</h3>
                  <p>with 100% Refund Offer</p>
                </div>
                <span>₹ 699</span>
              </div>
              <div className="coupon-input">
                <input
                  type="text"
                  name="coupon"
                  id="coupon"
                  value={coupon}
                  onChange={handleInputChange}
                  placeholder="Enter Coupon Code"
                />

                {!applied && (
                  <button onClick={handleApply} className="button">
                    <span id="apply">Apply</span>
                  </button>
                )}
                {applied && (
                  <button className="button">
                    <span id="apply">Applied</span>
                  </button>
                )}
              </div>
              <div className="applied">
                <div className="rotated-tag">
                  <span>APPLIED</span>
                </div>
                <div>
                  <h3>FEESBACK</h3>
                  <span>for limited period only</span>
                  <p>
                    You are now eligible for getting 100% of your enrollment fee
                    back on your course completion.
                  </p>
                </div>
              </div>
            </div>
            <div className="bill">
              <table>
                <tr>
                  <th style={{ textAlign: "left" }}>Total</th>
                  <th>
                    ₹<span id="initialprice">699</span>
                  </th>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    <span id="discount">{discount}</span>
                  </td>
                </tr>
                <tr>
                  <td>Promo Applied</td>
                  <td>100% Refund</td>
                </tr>
              </table>
              <div className="linee"></div>
              <p className="total">
                <span>Total</span>
                <span>
                  ₹<span id="finalprice">{699 - discount}</span>
                </span>
              </p>
              <button
                onClick={() => {
                  setCompleted([...completed, 1]);
                  // setOnit(2);

                  window.fbq("track", " InitiateCheckout");
                  checkoutHandler(
                    699 - discount,
                    name,
                    email,
                    selectedCategory,
                    coupon
                  );
                }}
                className="button"
              >
                Proceed to Pay
              </button>
            </div>
          </div>
        </main>

        <footer className="footer">
          <div>
            <img
              src="https://tutedude.in/feesbackassets/100Safe&secure.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://tutedude.in/feesbackassets/SSLEncryption.png"
              alt=""
            />
          </div>
          <div>
            <img src="https://tutedude.in/feesbackassets/Razorpay.png" alt="" />
          </div>
        </footer>
      </div>
    </>
  );
};

export default Payment;
