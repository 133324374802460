import { useEffect, useRef, useState } from "react";
import BrowseCourses from "../Components/HomePage/Components/BrowseCourses";
import Hero from "../Components/HomePage/Components/Hero";
import HowDifferent from "../Components/HomePage/Components/HowDifferent";
import Internship from "../Components/HomePage/Components/Internship";
import IntroFaq from "../Components/HomePage/Components/IntroFaq";
import IntroFooter from "../Components/HomePage/Components/IntroFooter";
import Whyus from "../Components/HomePage/Components/Whyus";

import SignUp from "../Components/SignUp";
import Login from "../Components/Login";
import WhyRefund from "../Components/WhyRefund";
import OtherCourses from "../Components/OtherCourses";
import Experiences from "../Components/Experiences";
import ProPackCards from "../Components/HomePage/Components/ProPackCards";
import { useLocation } from "react-router-dom";
import ForgotPass from "../Components/ForgotPass";
import Refund from "../Components/Refund";
import VideoCarousel from "../Components/VideoCarousel";

const Main = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const customSlideToExploreRef = useRef(null);
  const { hash } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    try {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [hash]);

  return (
    <div>
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      {showSign && (
        <SignUp setShowSign={setShowSign} setShowLogin={setShowLogin} />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          setShowForgot={setShowForgot}
          setShowSign={setShowSign}
        />
      )}
      <Hero setShowLogin={setShowLogin} setShowSign={setShowSign} />
      <div ref={customSlideToExploreRef}>
        <BrowseCourses />
      </div>

      <ProPackCards />
      <OtherCourses hideHeader={true} />
      <Internship />
      {/* <HowRefund cname={"Any"} /> */}
      <Refund price="699" months="3" />
      <WhyRefund />
      <Whyus />
      <HowDifferent />

      <VideoCarousel />
      <Experiences />
      <IntroFaq />
      <IntroFooter />
    </div>
  );
};

export default Main;
