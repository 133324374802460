import React, { useEffect, useState } from "react";
import "./TrackPaymentOld.css";
import secure1 from "../Assests/latest/100secure.png";
import secure from "../Assests/latest/secure.png";
import Razorpay from "../Assests/latest/Razorpay.png";
import logo from "../Assests/logo.svg";
import axios from "axios";
import useCart from "../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const TrackPaymentOld = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState("");
  const [courses, setCourses] = useState("");
  const [trackName, setTrackName] = useState("");

  const navigate = useNavigate();

  const { selectedCourse } = useCart();
  useEffect(() => {
    const name = Cookies.get("user_name");
    let sls = "";
    const email = Cookies.get("user_email");
    if (!name || !email) {
      navigate("/");
    } else {
      setName(name);
      setEmail(email);

      window.fbq("track", "AddToCart");
      window.gtag("event", "conversion", {
        send_to: "AW-711435738/LjBwCMWls7oDENrLntMC",
      });
    }

    var p = 2999;
    if (JSON.stringify(selectedCourse) !== "{}") {
      if (selectedCourse?.tracks[0]?.find((sc) => sc.slug === "uiux")) p = 2499;
      setPrice(p);
      const slugString = selectedCourse?.tracks[0]
        ?.map((obj) => obj.slug)
        .join(",");
      setCourses(slugString);
      if (slugString === "cpp,appdevelopment,datastructure,java") {
        setTrackName("Road to an App Developer");
      } else if (
        slugString === "cpp,python,datastructure,competitiveprogramming"
      ) {
        setTrackName("Road to a Python Developer");
      } else if (
        slugString === "cpp,mernstack,datastructure,competitiveprogramming"
      ) {
        setTrackName("Road to a Web Developer");
      } else if (slugString === "cpp,datascience,datastructure,python") {
        setTrackName("Road to a Data Scientist");
      } else if (slugString === "uiux,photoshop,illustrator") {
        setTrackName("Road to a UI/UX designer");
      }
      sls = slugString;
    }

    fetch(
      `https://api.tutedude.com/lms/api/user/saveLeads?email=${email}&course=${sls}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
  }, [selectedCourse]);

  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [applied, setApplied] = useState(false);

  var coupons = {
    OFF200: 200,
    DevTest: 2998,
    ANUDEEPA50: 300,
    NAVNEETA50: 300,
    MANTRA30: 200,
    GENERIC30: 300,
    HTA30: 300,
    EXCEL200: 200,
    SKILL100: 100,
    PREP200: 200,
    INFOHOOP: 200,
    HACK200: 200,
    TD100: 100,
    DUDE30: 200,
    Dude30: 200,
    dude30: 200,
    DUDE300: 300,
    JEET200: 200,
    SRMKZILLA: 300,
    DIWALI300: 300,
    GKARMY:100
  };
  const feesback = ["3MONTHSREFUND", "3MONTHREFUND", "FEESBACK", "LEARNIT"];

  const handleInputChange = (e) => {
    setApplied(false);
    setDiscount(0);
    setCoupon(e.target.value);
  };
  const handleApply = () => {
    if (
      feesback.findIndex(
        (fees) => fees.toLocaleLowerCase() === coupon.toLocaleLowerCase()
      ) !== -1
    ) {
      alert(
        "Congrats! You are eligible to get 100% feesback on completing this course within 1 year :)"
      );
      setDiscount(0);
      setApplied(true);
    } else if (coupons[coupon]) {
      setDiscount(coupons[coupon]);
      setApplied(true);
    } else if (!coupons[coupon]) {
      try {
        fetch("https://api.tutedude.com/verify?coupon=" + coupon, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setDiscount(200);
              setApplied(true);
            } else {
              alert("Please Enter a Valid Coupon");
              setDiscount(0);
            }
          });
      } catch (err) {
        alert("Please Enter a Valid Coupon");
        setDiscount(0);
      }
    }
  };

  const checkoutHandler = async (amount, name, email, courses, coupon) => {
    // console.log(amount, name, email, coupon, courses);
    const {
      data: { key },
    } = await axios.get("https://api.tutedude.com/lms/API/getkey");

    const {
      data: { order },
    } = await axios.post("https://api.tutedude.com/lms/API/checkout", {
      amount,
      name,
      email,
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "TuteDude",
      description: "TuteDude Course Purchase",
      image: logo,
      order_id: order.id,
      receipt: email,
      prefill: {
        name,
        email,
        contact: Cookies.get("user_phone") || "",
      },
      handler: function (response) {
        navigate(
          `/letskillit?amt=${amount}&pid=${response.razorpay_payment_id}`
        );
      },
      notes: {
        customer_name: name,
        customer_email: email,
        course: courses,
        coupon: coupon,
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <div className="html">
      <div className="tpo-cont">
        <div className="outerBox">
          <img
            src="https://tutedude.in/images/Tutedude.png"
            alt=""
            className="logo"
          />
          <p>
            We just don’t vouch for your Internship, we will build your career
          </p>
          <div className="innerbox">
            <div className="grid1">
              <h2>Internship Track</h2>
              <h3>{trackName}</h3>
              <div className="coupon">
                <input
                  type="text"
                  name="search"
                  value={coupon}
                  onChange={(e) => handleInputChange(e)}
                  id="coupon"
                  placeholder="Have any coupon code?"
                />
                {!applied && (
                  <button id="apply" onClick={handleApply}>
                    Apply
                  </button>
                )}
                {applied && <button id="apply">Applied</button>}
              </div>
            </div>
            <div className="grid2">
              <div className="line"></div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      Initial Price <span>:</span>
                    </td>
                    <td>₹ {price + 2000}</td>
                  </tr>
                  <tr>
                    <td>
                      Discount Applied &nbsp;<span>:</span>
                    </td>
                    <td>
                      -₹ <span id="discount">{2000 + discount}</span>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Final Price <span>:</span>
                    </th>
                    <th>
                      ₹ <span id="finalprice">{price - discount}</span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="grid3">
              <div className="appliedOffer">
                Limited Period Offer applied
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"></path>
                </svg>
              </div>
              <p>
                You Save : ₹ <span id="discount2">{2000 + discount}</span>
              </p>
            </div>
          </div>

          <button
            className="pay"
            onClick={() => {
              window.fbq("track", " InitiateCheckout");
              checkoutHandler(price - discount, name, email, courses, coupon);
            }}
          >
            Proceed to Pay
          </button>
        </div>
        <footer>
          <div>
            <img src={secure1} alt="" />
          </div>
          <div>
            <img src={secure} alt="" />
          </div>
          <div>
            <img src={Razorpay} alt="" />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TrackPaymentOld;
