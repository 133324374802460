import React, { useEffect, useState } from "react";
import "./Styles/Hero.css";

import Navbar from "./Navbar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useCart from "../contexts/Cart";
import StickyCards from "./StickyCards/StickyCards";
const Hero = ({
  setShowLogin,
  setShowSign,
  bought,
  cont,
  slug,
  setRedirect,
  newMOb
}) => {
  // console.log(newMOb)
  const name = Cookies.get("user_name");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const { setSelectedCategory } = useCart();
  return (
    <>
      <div className="hero-cont">
      {(windowWidth > 500  || !newMOb) &&  <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />}

        {(windowWidth > 500 || !newMOb) && <div className="hero-inner-cont">
         
          <div className="hero-left-cont">
            <div className="hero-iit-cont">
              An <b className="iit"> &nbsp; IIT Delhi &nbsp;</b> Alumni
              Initiative
            </div>
            <div className="hero-master">
              Learn <br />
              <b className="hero-uiux">{cont.name},</b> <br />
              effectively for
              <b className="hero-free"> Free</b>
            </div>
            <div className="hero-text">
              By getting <b>100%</b> of your <b>Money back</b> on course
              completion
            </div>
            <div className="hero-yellow-strip1">
              <div className="hero-line-bg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="14"
                  viewBox="0 0 24 14"
                  fill="none"
                  className="hero-line-bg-circle"
                >
                  <g filter="url(#filter0_f_656_3300)">
                    <path
                      d="M20 7C20 8.65685 18.2944 10 16.1905 10C14.0865 10 4 7 4 7C4 7 14.0865 4 16.1905 4C18.2944 4 20 5.34315 20 7Z"
                      fill="#9C60FF"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_656_3300"
                      x="0"
                      y="0"
                      width="24"
                      height="14"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_656_3300"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="hero-yellow-text">Enroll</div>
              
              <div className="hero-yellow-text">Upskill</div>
             
              <div className="hero-yellow-text">100% Refund</div>
            </div>
            
        
            <div className="hero-cta-cont">
           <div className="hero-price-cont">
                <div className="hero-price">₹699</div>
                <div className="hero-price-text">
                  Pay now & get money back in your <b>bank account</b> on course
                  completion
                </div>
              </div>
              {bought ? (
                <div
                  className="hero-enroll-btn"
                  onClick={() =>
                    window.open("https://upskill.tutedude.com/dashboard")
                  }
                >
                  Go to Dashboard
                </div>
              ) : (
                <div
                  className="hero-enroll-btn"
                  onClick={() => {
                    setSelectedCategory(slug);
                    if (!name) {
                      setRedirect(`/payment`);
                      setShowSign(true);
                    } else {
                      navigate(`/payment`);
                    }
                  }}
                >
                  Enroll Now
                </div>
              )}
            </div>
          </div>
          {windowWidth > 1020 && (
            <div className="hero-rright-cont">
              <div className="hero-right-vid-cont">
                <iframe
                  className="hero-video-img"
                  src={`${cont.video}?autoplay=1&mute=1`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
         
        </div>}
        {
          windowWidth <=500 && newMOb &&   <StickyCards setShowLogin={setShowLogin} setShowSign={setShowSign} text={cont.name}/>}
        {
          windowWidth <=500 && newMOb &&  <div className="hero-cta-cont"> <div className="hero-price-cont" style={{marginTop:"40px"}}>
              {bought ? (
                <div
                  className="hero-enroll-btn"
                  onClick={() =>
                    window.open("https://upskill.tutedude.com/dashboard")
                  }
                >
                  Go to Dashboard
                </div>
              ) : (
                <div
                  className="hero-enroll-btn"
                  onClick={() => {
                    setSelectedCategory(slug);
                    if (!name) {
                      setRedirect(`/payment`);
                      setShowSign(true);
                    } else {
                      navigate(`/payment`);
                    }
                  }}
                >
                  Enroll Now
                </div>
              )}
            </div>
            </div>
          }
        
      </div>
    </>
  );
};

export default Hero;
