import React, { useState } from 'react'
import Card from './Card';
import './StickyCard.css';
const StickyCards = ({setShowLogin ,setShowSign, text}) => {
  const [height,setHeight] = useState(179);
    const steps = [
        {
          stepNo: 1,
          stepHeading: "Enroll the course for just ₹699.",
          },
        {
          stepNo: 2,
          stepHeading: "Finish the course within 3 months.",
          },
        {
          stepNo: 3,
          stepHeading: "Get 100% money back in your bank.",
          }
      ];
  return (
    <div className="hackathon-right">
    {steps.map((item, idx) => (
          <div
            className={`hack-step-card-outer ${
              idx % 2 === 0 && idx!==0 && "card-even" 
            }  ${
              idx % 2 !== 0 && "card-odd" 
            }`}
            key={idx}
            style={{ '--card-height': `${height+10}px` }}
          >
            <Card item={item} text={text} setShowLogin={setShowLogin} setShowSign={setShowSign} setHeight={setHeight} height={height}/>
          </div>
        ))}
      </div>
  )
}

export default StickyCards