import mern from "../Assests/Images/mern.webp"
import dsa from "../Assests/Images/datastructure.png";
import cpp from "../Assests/Images/c++.png";
import competitiveprogramming from "../Assests/Images/CompetitiveProgramming.svg";
import python from "../Assests/Images/python.png";
import javaa from "../Assests/Images/java.png";
import ad from "../Assests/Images/app.svg";
import ds from "../Assests/Images/science.webp";
import ml from "../Assests/Images/ml.webp";
import uxresearch from "../Assests/uxresearch.png";
import figma from "../ProPacks/assets/figma.png";
import pss from "../Assests/Images/photoshop.jpg";
import aii from "../Assests/Images/illustrator.webp";

const tracks = {
    "fullstackdevelopment": {
        "heroHead": "Full Stack Web Development",
        "curricHead": "Full Stack Developer",
        "title": "Full Stack Track",
        "curricSubHead": "Full Stack",
        "price": "2,999",
        "discount": "4,999",
        "curric": [
            {
                "name": "C++",
                "details": "We will teach C++ to build your fundamentals and strong foundation in programming. It is the best language to start with coding.",
                "slug": "cpp",
                "duration": "35hr 10min",
                src: cpp
            },
            {
                "name": "MERN Stack",
                "details": "MERN stack is the best stack and very high in demand among employers and big companies. We will be learning this extensively and build high end projects.",
                "slug": "mernstack",
                "duration": "35hr 10min",
                src: mern
            },
            {
                "name": "Data Structure & Algorithm",
                "details": "DSA is the most important thing to crack interviews, this is the thing on which you will be judged about your skills. We will be learning it together extensively with many assignments to practice and master it",
                "slug": "datastructure",
                "duration": "35hr 10min",
                src: dsa,
            },
            {
                "name": "Competitve Programming",
                "details": "Competitive programming is needed to hone your skills in coding and crack interviews of very good companies. Once you indulge in it it will be like an addiction but a good one :)",
                "slug": "competitiveprogramming",
                "duration": "35hr 10min",
                src: competitiveprogramming
            }
        ]
    },
    "pythondevelopment": {
        "heroHead": "Python Development",
        "curricHead": "Python Developer",
        "curricSubHead": "Python",
        "title": "Python Track",
        "price": "2,999",
        "discount": "4,999",
        "curric": [
            {
                "name": "C++",
                "details": "We will teach C++ to build your fundamentals and strong foundation in programming. It is the best language to start with coding.",
                "slug": "cpp",
                "duration": "35hr 10min",
                src: cpp
            },
            {
                "name": "Python",
                "details": "Python is the best language and very high in demand among employers and big companies. We will be learning this extensively and build high end projects.",
                "slug": "python",
                "duration": "35hr 10min",
                src: python
            },
            {
                "name": "Data Structure & Algorithm",
                "details": "DSA is the most important thing to crack interviews, this is the thing on which you will be judged about your skills. We will be learning it together extensively with many assignments to practice and master it",
                "slug": "datastructure",
                "duration": "35hr 10min",
                src: dsa
            },
            {
                "name": "Competitve Programming",
                "details": "Competitive programming is needed to hone your skills in coding and crack interviews of very good companies. Once you indulge in it it will be like an addiction but a good one :)",
                "slug": "competitiveprogramming",
                "duration": "35hr 10min",
                src: competitiveprogramming
            }
        ]
    },
    "appdevelopment": {
        "heroHead": "App Development",
        "title": "App Development Track",
        "curricHead": "App Developer",
        "curricSubHead": "App",
        "price": "2,999",
        "discount": "4,999",
        "curric": [
            {
                "name": "C++",
                "details": "We will teach C++ to build your fundamentals and strong foundation in programming. It is the best language to start with coding.",
                "slug": "cpp",
                "duration": "35hr 10min",
                src: cpp
            },
            {
                "name": "Data Structure & Algorithm",
                "details": "DSA is the most important thing to crack interviews, this is the thing on which you will be judged about your skills. We will be learning it together extensively with many assignments to practice and master it",
                "slug": "datastructure",
                "duration": "35hr 10min",
                src: dsa
            },
            {
                "name": "JAVA",
                "details": "Java is the fundamental language through which android apps are built. And that's why we will be learning it thoroughly before moving to android development.",
                "slug": "java",
                "duration": "35hr 10min",
                src: javaa
            },
            {
                "name": "App Development",
                "details": "In this course we will now learn how to build apps through both Java and Kotlin, till now you would have made all your fundamentals super strong and with this course we will connect everything.",
                "slug": "appdevelopment",
                "duration": "35hr 10min",
                src: ad
            }
        ]
    },
    "datascience": {
        "heroHead": "Data Science",
        "title": "Data Science Track",
        "curricHead": "Data Scientist",
        "curricSubHead": "Data Science",
        "price": "2,999",
        "discount": "4,999",
        "curric": [
            {
                "name": "Python",
                "details": "Python is the best language and very high in demand among employers and big companies. We will be learning this extensively and build high end projects.",
                "slug": "python",
                "duration": "35hr 10min",
                src: python
            },
            {
                "name": "DSA with Python",
                "details": "DSA is the most important thing to crack interviews, this is the thing on which you will be judged about your skills. We will be learning it together extensively with many assignments to practice and master it",
                "slug": "dsawithpython",
                "duration": "35hr 10min",
                src: dsa
            },
            {
                "name": "Data Science",
                "details": "So after building our fundamentals very strong, we will learn Data Science where we will cover all the topics needed for the industry.",
                "slug": "datascience",
                "duration": "35hr 10min",
                src: ds
            },
            {
                "name": "Machine Learning",
                "details": "With this we will go deep into important concepts of Data Science like regression, KNN, neural networks etc.",
                "slug": "machinelearning",
                "duration": "35hr 10min",
                src: ml
            }
        ]
    },
    "uiuxtrack": {
        "dbms": true,
        "heroHead": "UI/UX",
        "title": "UI/UX track",
        "curricHead": "UI/UX designer",
        "curricSubHead": "UI/UX",
        "price": "2,499",
        "discount": "4,999",
        "curric": [
            {
                "name": "UI/UX",
                "details": "In this course you will learn all the concepts of UI/UX in depth from basics to advanced level.",
                "slug": "uiux",
                "duration": "35hr 10min",
                src: figma

            },
            {
                "name": "UX Research",
                "details": "Ux Research is the most important part of product designing. So Learn from the best and become pro in UX.",
                "slug": "uxresearch",
                "duration": "35hr 10min",
                src: uxresearch
            },
            {
                "name": "Photoshop",
                "details": "Photoshop is a main tool for digital design, so we will teach you Photoshop so that you can create designs and graphics needed for your UI/UX. It is a skill which every company demands from an UI/UX designer.",
                "slug": "photoshop",
                "duration": "35hr 10min",
                src: pss
            },
            {
                "name": "Adobe Illustrator",
                "details": "Illustrator is required to create vectors for your design. We will teach you Illustrator so that you can build elements in your design fluently. If you learn it well, you’ll become gurus of design. Sky is the limit. Case Studies",
                "slug": "illustrator",
                "duration": "35hr 10min",
                src: aii
            }
        ]
    }
}

export default tracks;