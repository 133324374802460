import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from "./contexts/Cart";
import Main from "./Pages/Main";
import Category from "./Pages/Category";
import Payment from "./Pages/Payment";
import AllAccess from "./Pages/AllAccess";
import CustomPayment from "./Pages/CustomPayment";
import Success from "./Pages/Success";
import TrackPaymentOld from "./Pages/TrackPaymentOld";
import Layout from "./InternshipTrack/pages/Layout";
import Layout1 from "./CustomPack/pages/Layout";
import Upgrade from "./UpgradePage/pages/Layout";
import UpgradePro from "./UpgradePro/UpgradePro";
import UpgFullStack from "./UpgradeFullStack/pages/UpgFullStack";
import UpgAllAccess from "./UpgradeAllAccess/pages/UpgAllAccess";
import About from "./FooterPages/pages/About";
import Privacy from "./FooterPages/pages/Privacy";
import RefundPolicy from "./FooterPages/pages/RefundPolicy";
import TermsAndCondition from "./FooterPages/pages/TermsAndCondition";
import HeroMobile from "./Components/StickyCards/HeroMobile";
import DoubtSolve from "./Components/Reusable/DoubtSolve";

function App() {
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    const selectedCourses = JSON.parse(localStorage.getItem("selectedCourses"));

    if (selectedCourses) {
      setSelectedCourse(selectedCourses);
    }
    const selectedCategory = localStorage.getItem("selectedCategory");
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedCourse) !== "{}") {
      localStorage.setItem("selectedCourses", JSON.stringify(selectedCourse));
    }
  }, [selectedCourse]);

  useEffect(() => {
    localStorage.setItem("selectedCategory", selectedCategory);
  }, [selectedCategory]);

  return (
    <CartProvider
      value={{
        selectedCourse,
        setSelectedCourse,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="" element={<Main />} />
            <Route path="courses" element={<TrackUser />} />
            <Route path="category/:slug" element={<Category />} />
            <Route path="category/upgrade" element={<Upgrade />} />
            <Route path="packpayment" element={<CustomPayment />} />
            <Route path="trackspayment" element={<TrackPaymentOld />} />
            <Route path="payment" element={<Payment />} />
            <Route path="category/customizepack" element={<Layout1 />} />
            <Route path="category/allaccess" element={<AllAccess />} />
            <Route path="tracks/:slug" element={<Layout />} />
            <Route path="letskillit" element={<Success />} />
            <Route path="upgrade/pro/:slug" element={<UpgradePro />} />
            <Route path="upgrade/internship/:slug" element={<UpgFullStack />} />
            <Route path="upgrade/allaccess" element={<UpgAllAccess />} />

            <Route path="/test" element={<>
              <HeroMobile />
              <DoubtSolve />
            </>} />

            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-condition" element={<TermsAndCondition />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;

const TrackUser = () => {
  useEffect(() => {
    const trackUser = async () => {
      try {
        const response = await fetch(
          "https://api.tutedude.com/lms/v1/visitor",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          console.log("Loading courses...");
        } else {
          console.error("Loaded Courses");
        }
        window.location.href = "https://tutedude.com/#courses";
      } catch (error) {
        console.log("Error:", error);
      }
    };
    trackUser();
  }, []);

  return <div>Loading...</div>;
};
