import React, { useEffect, useRef } from 'react';
import './card.css'
import Navbar from '../Navbar';

function Card({ item,setShowLogin,setShowSign,text ,setHeight,height}) { 
    const divRef = useRef(null);
    var col = "#FCF5FE";
    var pad = "0px"
    var transform = "rotate(-2deg) scale(0.9)"
    if(item.stepNo === 2){
        col ="#F9EBFC";
        pad="40px";
        transform="none"
    }if(item.stepNo ===3){
        col = "#F4D6F8";
        pad="40px";
        transform="none";
    }
   
    const st = {
        backgroundColor:col,
        marginTop:pad,
        transform:transform
    }

    useEffect(() => {
        const updateHeight = () => {
            if (divRef.current) {
                setHeight(divRef.current.offsetHeight);
            }
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [height]);

    return (
       <div className='card-sticky-cont'>
        
        {item.stepNo ===1 &&<>
           

    <div className="hero-inner-cont" ref={divRef}>
      <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />
      <div className="hero-left-cont">
        <div className="hero-iit-cont">
          An <b className="iit"> &nbsp; IIT Delhi &nbsp;</b> Alumni
          Initiative
        </div>
        <div className="hero-master">
          Learn <br />
          <b className="hero-uiux">{text},</b> <br />
          effectively for
          <b className="hero-free"> Free</b>
        </div>
        </div>
        </div>
        </> }
         <div className="hack-step-card" style={st}>
            <div className="hack-step-card-heading">
                {item.stepHeading}
            </div>
           
        </div>
       </div>
    );
}

export default Card;