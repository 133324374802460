import React, { useEffect, useRef, useState } from "react";
import "./UpgAllAccess.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Testimonials from "../../ProPacks/pages/home/Testimonials";
import Faq from "../../InternshipTrack/pages/home/Faq";
import Footer from "../../InternshipTrack/pages/home/Footer";
import Hero from "./home/Hero";
import Refund from "./home/Refund";
import Courses from "./home/Courses";
import Benefits from "./home/Benefits";
import "./global.css";
import UpgradeStickyCard from "../../UpgradeFullStack/components/reusable/UpgradeStickyCard";
import { Helmet } from "react-helmet";
import SignUp from "../../Components/SignUp";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import Cookies from "js-cookie";

import coursesData from "../../contents/Courses.json";
import { useNavigate } from "react-router-dom";
import useCart from "../../contexts/Cart";

function UpgAllAccess(props) {
  const email = Cookies.get("user_email");
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [notPurchasedCourses, setNotPurchasedCourses] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [showForgot, setShowForgot] = useState(false);
  const [price, setPrice] = useState(3799);
  const [totalAmount, setTotalAmount] = useState(0);
  const customSlideToExploreRef = useRef(null);

  const [courses, setCourses] = useState(coursesData);

  const { setSelectedCourse, setSelectedCategory } = useCart();
  const navigate = useNavigate();

  const getCoursesToPurchase = (courses) => {
    const notPurchasedCourses = courses?.filter(
      (item) => item?.purchased === false
    );

    const modifiedCourses = notPurchasedCourses?.map((course) => ({
      url: course.slug,
      title: course.course_name,
      src: course.img,
      category: course.category,
      purchased: course.purchased,
    }));

    setNotPurchasedCourses(modifiedCourses);
    return modifiedCourses;
  };

  const fetchAndAddPurchaseStatus = async (email) => {
    try {
      const response = await fetch(
        `https://api.tutedude.com/studentinfo?student_id=${email}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();

      if (!data.success) {
        throw new Error("Failed to fetch user data");
      }

      const purchasedSlugsSet = new Set(
        data?.dashboard?.stdata?.flatMap((item) => item.sub_category.split(","))
      );

      const total = data?.dashboard?.stdata?.reduce(
        (sum, item) => sum + item.amount,
        0
      );
      setTotalAmount(total);

      setCourses((prev) => {
        let updatedCourse;
        if (prev.length > 0) {
          updatedCourse = prev?.map((item) => {
            return {
              ...item,
              purchased: purchasedSlugsSet.has(item?.slug),
            };
          });
        }
        return updatedCourse;
      });
    } catch (error) {
      console.error("Error fetching purchase data:", error);
    }
  };

  useEffect(() => {
    if (email) fetchAndAddPurchaseStatus(email);
  }, [email]);

  useEffect(() => {
    const dt = getCoursesToPurchase(courses);

    let amount = totalAmount || 0;
    if (dt.length === 0) amount = 4499;
    if (email) {
      setPrice(Math.max(4499 - amount,699));
    } else {
      setPrice(3799);
    }

  }, [courses, totalAmount, email]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="alac-pack-layout">
        <Helmet>
          <title>All Access Pack Page</title>
        </Helmet>
        {showSign && (
          <SignUp
            setShowSign={setShowSign}
            redirect={redirect}
            setShowLogin={setShowLogin}
          />
        )}
        {showLogin && (
          <Login
            setShowLogin={setShowLogin}
            redirect={redirect}
            setShowSign={setShowSign}
            setShowForgot={setShowForgot}
          />
        )}
        {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
        <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />
        <Hero
          price={price}
          setShowLogin={setShowLogin}
          notPurchasedCourses={notPurchasedCourses}
        />
        <div ref={customSlideToExploreRef}>
          <Courses courses={courses} />
        </div>
        <Refund price={price} />
        <Benefits
          price={price}
          setShowLogin={setShowLogin}
          notPurchasedCourses={notPurchasedCourses}
        />
        <Faq />
        <Footer />

        {showFloatSelected && (
          <div className="win-float-cont">
            <div className="abcd-layout-bottom">
              <div className="abcd-hm-left">
                <span className="">{((price / 18873) * 100).toFixed(0)}% </span>
                <span className="">OFF</span>
              </div>
              <div className="abcd-hm-middle">
                <p className="abcd-fw-700 abcd-layout-price-bottom abcd-text-pink abcd-text-center">
                  <span className="abcd-layout-bottom-small">₹{price}</span>
                </p>
                <p
                  className="abcd-fw-700 abcd-f-16 abcd-text-pink abcd-text-center"
                  style={{ color: "#263238" }}
                >
                  Total Price
                </p>
              </div>
              <div className="abcd-hm-right">
                <div
                  className="abcd-proceed abcd-layout-proceed abcd-text-white"
                  onClick={() => {
                    setSelectedCategory(`upgrade-pro-allaccess#${price}`);

                    setSelectedCourse((prev) => ({
                      ...prev,
                      upgrade: notPurchasedCourses,
                    }));
                    if (!Cookies.get("user_email")) {
                      setShowLogin(true);
                    } else {
                      if (notPurchasedCourses?.length > 0) {
                        navigate("/packpayment");
                      } else {
                        alert("Already purchased all courses");
                        window.location.href =
                          "https://upskill.tutedude.com/dashboard";
                      }
                    }
                  }}
                >
                  Upgrade Now
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UpgAllAccess;
