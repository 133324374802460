import React, { useEffect, useRef, useState } from "react";
import "./UpgFullStack.css";
import Cookies from "js-cookie";
// import Hero from "./home/Hero";
import Hero from "../../InternshipTrack/pages/home/Hero";
import Roadmap from "./home/Roadmap";
import Best from "../../InternshipTrack/pages/home/Best";
import "../global.css";
import Highlights from "../../InternshipTrack/pages/home/Highlights";
import Faq from "../../InternshipTrack/pages/home/Faq";
import Footer from "../../InternshipTrack/pages/home/Footer";
import VideoCarousel from "../../Components/VideoCarousel";
// import upload from "../assets/upload.svg";
import OneGuarantee from "../../InternshipTrack/pages/home/OneGuarantee";
import Internship from "../../InternshipTrack/pages/home/Internship";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Guarantee from "../../InternshipTrack/pages/home/Guarantee";
import UpgradeStickyCard from "../components/reusable/UpgradeStickyCard";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import SignUp from "../../Components/SignUp";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import tracks from "../../contents/Tracks";

function UpgFullStack() {
  const { slug } = useParams();
  const email = Cookies.get("user_email");
  const [internPack, setInternPack] = useState(tracks[slug]);
  const [notPurchasedCourses, setNotPurchasedCourses] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [showForgot, setShowForgot] = useState(false);

  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const customSlideToExploreRef = useRef(null);

  const [price, setPrice] = useState(2299);
  const [coursesBought, setCoursesBought] = useState(0);

  const navigate = useNavigate();

  const ids = [
    "fullstackdevelopment",
    "pythondevelopment",
    "appdevelopment",
    "datascience",
    "uiuxtrack",
  ];

  const fetchAndAddPurchaseStatus = async (email) => {
    try {
      const response = await fetch(
        `https://api.tutedude.com/studentinfo?student_id=${email}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();

      if (!data.success) {
        throw new Error("Failed to fetch user data");
      }

      const purchasedSlugsSet = new Set(
        data?.dashboard?.stdata?.flatMap((item) => item.sub_category.split(","))
      );

      setCoursesBought(purchasedSlugsSet.size);

      setInternPack((prev) => {
        const updatedCurric = prev?.curric.map((item) => {
          return {
            ...item,
            purchased: purchasedSlugsSet.has(item.slug),
          };
        });

        return {
          ...prev,
          curric: updatedCurric,
        };
      });
    } catch (error) {
      console.error("Error fetching purchase data:", error);
    }
  };

  const getCoursesToPurchase = (track) => {
    const notPurchasedCourses = track?.curric?.filter(
      (item) => item?.purchased === false
    );

    const modifiedCourses = notPurchasedCourses?.map((course) => ({
      url: course?.slug,
      title: course?.name,
      src: course?.src,
      details: course?.details,
      purchased: course?.purchased,
      duration: course?.duration,
    }));

    setNotPurchasedCourses(modifiedCourses);
    return modifiedCourses;
  };

  useEffect(() => {
    if (email) fetchAndAddPurchaseStatus(email);
  }, [email, slug]);

  useEffect(() => {
    const dt = getCoursesToPurchase(internPack);
    const len = internPack?.curric?.length - dt?.length;

    if (!email) {
      setPrice(2299);
    } else {
      if (len === 0) {
        setPrice(2999);
      } else if (len === 1) {
        setPrice(2999 - 700);
      } else if (len >= 2) {
        setPrice(2999 - 1500);
      }

      if (len === internPack?.curric.length) {
        setPrice(0);
      }
    }
  }, [email, coursesBought, internPack]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!ids.includes(slug)) {
    navigate("/");
    return <>Wrong Url...</>;
  }
  return (
    <>
      <Helmet>
        <title>Internship Pack</title>
      </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          redirect={redirect}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}

      <div className="upg-fs-layout">
        <Navbar setShowSign={setShowSign} setShowLogin={setShowLogin} />
        <Hero
          notPurchasedCourses={notPurchasedCourses}
          setRedirect={setRedirect}
          setShowLogin={setShowLogin}
          setShowForgot={setShowForgot}
          setShowSign={setShowSign}
          data={{
            ...internPack,
            upgrade: true,
            pink: "Upgrade",
            text: "Upgrade now for ₹",
          }}
          price={price}
        />
        <Roadmap
          track={internPack}
          price={price}
          notPurchasedCourses={notPurchasedCourses}
          setShowLogin={setShowLogin}
        />
        <div ref={customSlideToExploreRef}>
          <OneGuarantee
            head={"Guarantee Card"}
            heroHead={internPack?.heroHead}
          />
        </div>

        <Highlights />
        <Internship />
        <Guarantee
          data={{
            ...internPack,
            upgrade: true,
          }}
          setRedirect={setRedirect}
          setShowLogin={setShowLogin}
          price={price}
          notPurchasedCourses={notPurchasedCourses}
        />

        <VideoCarousel />
        <Best />
        <Faq />
        <Footer />
      </div>
      {showFloatSelected && (
        <UpgradeStickyCard
          off={(((2999 - price) * 100) / 2999).toFixed(0)}
          dis={internPack?.discount}
          price={price}
          notPurchasedCourses={notPurchasedCourses}
          setShowLogin={setShowLogin}
        />
      )}
    </>
  );
}

export default UpgFullStack;
